import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SolutionArticle from "../components/solutionArticle"
import RouteListing from "../components/routeListing"

const IndexPage = () => {

    const product = require('../../product.json');

    return <Layout>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div class="mx-auto h-full py-16 max-w-lg text-center lg:mx-0 lg:text-left flex align-top flex-col">
                    <h1 class="text-3xl font-bold sm:text-4xl">SDKs</h1>
                    <p class="mt-4 text-gray-600">
                    Currently, {product.name} supports {} languages. Each SDK is listed in its respective package manager and contains documentation.
                    </p>
                </div>
            </div>
        </section>
        <section>
            <div class="mx-auto max-w-screen-xl px-4 pb-16 sm:px-6 lg:px-8">
                <div class="grid grid-cols-2 gap-4">
                    {
                        // routes.map((item) => RouteListing({ ...item, href: `/products/${item.slug}` }))
                    }
                </div>
            </div>
        </section>
    </Layout>
}

export const Head = () => <Seo title="Solutions" />

export default IndexPage
